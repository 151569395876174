<template>
  <div class="reportDetail">
    <div class="card-header">
      <span>Reports</span>
    </div>
    <div class="reportText">
      <el-form :label-position="labelPosition"
               label-width="80px"
               :model="formLabelAlign">
        <el-form-item label="Product Name">
          <el-input v-model="formLabelAlign.name"
                    placeholder="Enter product name"></el-input>
        </el-form-item>
        <el-form-item label="Order Number">
          <el-input v-model="formLabelAlign.Number"
                    placeholder="Enter order number"></el-input>
        </el-form-item>
        <el-form-item label="Reason">
          <el-radio-group @change='radioChange()'
                          v-model="formLabelAlign.radio">
            <el-radio :label="item.label"
                      :value="item.value"
                      v-for="(item, index) in radioList"
                      :key='index'>{{item.value}}</el-radio>
          </el-radio-group>
          <div v-if="radioBool">
            <textarea name=""
                      id=""
                      placeholder="Please fill in other reasons."
                      cols="180"
                      rows="10"
                      v-model="textValue"></textarea>
          </div>
        </el-form-item>
        <el-form-item label="Screenshots">
           <el-upload class="upload-demo"
                         action="https://crediscounts.com/photograph/picture"
                         :headers="chatToken"
                         :on-success="handlePreview"
                         :on-remove="handleRemove"
                         :before-upload="beforeAvatarUpload"
                         list-type="picture"
                         ref='upload'>
                <i class="el-icon-picture-outline"></i>
                <div slot="tip"
                     class="el-upload__tip">Can only upload pictures, and no more than 3MB</div>
              </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="submit()" :loading="$store.state.loadingBool">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  orderNumber // 添加投诉
} from '../../api/buyerAfter'
import {
  getToken
} from '../../utils/token'
export default {
  data () {
    return {
      // 对齐方式
      labelPosition: 'top',
      // 投诉表单
      formLabelAlign: {
        name: '',
        Number: '',
        radio: 'No refund for more than a week'
      },
      // 投诉原因
      radioList: [
        {
          label: 'No refund for more than a week',
          value: 'No refund for more than a week'
        },
        {
          label: 'Private transactions through other websites',
          value: 'Private transactions through other websites'
        },
        {
          label: '3',
          value: 'Other（Please fill in other reasons）'
        }
      ],
      textValue: '',
      // 聊天室上传图片列表
      chatToken: '',
      fileList: [],
      // 其他原因
      radioBool: false
    }
  },
  created () {
    this.chatToken = { Authorization: getToken() }
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 图片删除
    handleRemove (file, fileList) {
      this.fileList = []
      fileList.map(v => {
        this.fileList.push(`https://crediscounts.com/photograph/images/${v.response.message}`)
      })
    },
    // 图片上传成功
    handlePreview (file, fileList) {
      this.fileList.push(`https://crediscounts.com/photograph/images/${fileList.response.message}`)
    },
    // 图片上传之前
    beforeAvatarUpload (file) {
      const srcSize = file.size < (3072 * 3072)
      const srcReg = /image/g
      const srcBool = srcReg.test(file.type)
      if (!srcBool) {
        this.$message.error('Please upload the address')
        return false
      } else {
        if (!srcSize) {
          this.$message.error('The image size is less than 3MB')
          return false
        } else if (this.fileList.length > 2) {
          this.$message.error('Send up to three pictures at a time')
          return false
        }
      }
    },
    // 单选
    radioChange () {
      if (this.formLabelAlign.radio === '3') {
        this.radioBool = true
      } else {
        this.radioBool = false
      }
      // (this.formLabelAlign.radio)
    },
    // 提交
    submit () {
      if (this.formLabelAlign.Number === '') {
        this.$message.error('Order number cannot be empty')
      } else {
        (JSON.stringify(this.fileList))
        this.$store.commit('getLoading', true)
        // 添加投诉
        orderNumber({
          orderNumber: this.formLabelAlign.Number,
          reportDto: {
            order_number: this.formLabelAlign.Number,
            reason: this.formLabelAlign.radio === '3' ? this.textValue : this.formLabelAlign.radio,
            screenshot_url: JSON.stringify(this.fileList),
            user_id: localStorage.getItem('buyer-userId')
          }
        }).then(result => {
          if (result.data.code === 201) {
            this.$message.error(result.data.message)
            this.$store.commit('getLoading', false)
          } else if (result.data.code === 200) {
            this.$router.push('/A_report')
            this.$store.commit('getLoading', false)
          }
          // ('result =>', result)
        }).catch(err => {
          return err
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.reportDetail {
  height: 100%;
  background: white;
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      font-weight: bolder;
      margin-left: 10px;
      line-height: 45px;
    }
  }
  .reportText {
    background: white;
    padding: 15px;
    textarea {
      padding: 10px;
    }
    /deep/ .el-upload-list {
      display: flex;
      justify-content: flex-start;
    }
    /deep/ .el-upload-list {
      width: 20%;
    }
    /deep/ .el-upload-list__item-thumbnail {
      height: auto;
    }
  }
}
</style>
